import { Roles } from "../utils/mappings";

export const canCreateInvoice = (user) => {
	switch (user?.role) {
		case Roles.ACCOUNTANT:
		case Roles.CREATOR:
			return true;
	}

	return false;
};

//ACCOUNTANT-> can edit both tables
//ASSISTANT -> can edit second tabled -> can pass it to mannager
//HEAD -> can edit second tabled -> reject -> back to assistant
//ASSISTANT -> can edit second tabled -> can pass it to mannager

export const canEdit = (user) => {
	switch (user?.role) {
		case Roles.CREATOR:
			return false;
	}

	return true;
};


