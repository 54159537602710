import React, { useState } from "react";
import { scroller } from "react-scroll";
import { postUrl } from "../../api/common";

const ticks = [
	"Sasaiste ar VEDLUDB sistēmu",
	"Auditācijas pierakstu kontrolsumma",
	"Ārējā drošības pārbaude",
	"Pielāgota dažādu nozaru uzņēmumiem"
];

const HomeFirstRow = () => {
	const [email, setEmail] = useState("");
	const [emailSuccess, setEmailSuccess] = useState(false);

	const sendMail = async () => {
		await postUrl(
			"https://citylight.net/pages/confirm.php",
			{
				action: "send_contacts",
				to: "info@edlus.app",
				subject: `Inquiry for Edlus from ${email}`,
				text: `Jauns pieteikums no ${email}`
			}, 
			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);
		await setEmail("");
		await setEmailSuccess(true);
	};

	const srollTo = (name) => {
		scroller.scrollTo(name, {
			duration: 100,
			delay: 0,
			smooth: true
			// containerId: "ContainerElementID",
			// offset: 50 // Scrolls to element + 50 pixels down the page
		});
	};

	return (
		<div className="home-first d-flex flex-column justify-content-center align-items-center ">
			<div className="position-absolute blue-bg d-none d-xl-block" />
			<div className="position-absolute audit d-none d-xl-block">
				<img className="align-self-center" src={"images/audit_ico.svg"} />
			</div>

			<div className="position-absolute image d-none d-xl-block">
				<img
					className="align-self-center"
					src={"images/laptop_ico.png"}
					style={{ maxHeight: 628 }}
				/>
			</div>

			<div className="container d-flex flex-column justify-content-center align-content-center">
				<div className="col-xs-12 col-xl-6 subscribtion d-flex flex-column justify-content-start align-content-start">
					<div className="row">
						<div className="title">
							Mūsdienīga un likumdošanai atbilstoša elektroniskās darba laika
							uzskaites sistēma
						</div>
					</div>
					<div className="row">
						<ul class="">
							{ticks.map((_, index) => {
								return <li class="mt-3 mt-lg-0">{ticks[index]}</li>;
							})}
						</ul>
					</div>

					<div className="row email-input email-input-white flex-row justify-content-around align-items-center align-self-start my-2 mb-2 d-none d-md-flex">
						<input
							className="mx-5"
							type="text"
							placeholder="@tava epasta adrese"
							value={email}
							onChange={(e) => setEmail(e?.target?.value)}
						></input>
						<div
							className="button d-flex justify-content-center align-items-center blue hover-opacity"
							onClick={() => sendMail()}
						>
							<div>Piesakies</div>
						</div>
					</div>

					{emailSuccess && (
						<div className="pt-2">
							Paldies par pieteikumu! Mēs ar Jums sazināsimies tuvāko dienu
							laikā.
						</div>
					)}

					<div
						className="button-common align-content-center justify-content-center mx-2 d-flex d-md-none hover-opacity"
						style={{ height: 64 }}
						onClick={() => srollTo("try-container")}
					>
						<div className="align-self-center px-4">Piesakies</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeFirstRow;
